import React, {Component} from 'react';
import Girl1 from './Girl1';
import Girl2 from './Girl2';
import {connect} from 'react-redux';
import {addScore} from '../../store/actions/girlActions';
import { firestoreConnect } from 'react-redux-firebase';
import {compose} from 'redux';
import {Redirect} from 'react-router-dom';


class Home extends Component{
    //add state
    handleClick = (girl) => {
        //console.log(girl)
        this.props.addScore(girl)
        this.forceUpdate()
    }

    
    render(){
        //console.log(this.props);
        const {girls, auth} = this.props;
        if(!auth.uid) return <Redirect to='/signin'/>
        return(
            <div className="dashboard container">
                <h6 className="center">Were we let in for our looks? No. Will we be judged on them? Yes.</h6>
                <h2 className="center">Who's Hotter? Click to Choose.</h2>
                <div className="row">

                    <div className="col s12 m5">
                        <Girl1 girls={girls} handleClick={this.handleClick}/>
                    </div>

                    <div className="col s12 m5 offset-m2">
                        <Girl2 girls={girls} handleClick={this.handleClick}/>
                    </div>
                </div>
            </div>
            
        )
    }
}



const mapStateToProps = (state) => {
    console.log(state)
    return{
        girls: state.firestore.ordered.girls || state.girl.girls, //returns the collection of girls from firebase
        //girls: state.girl.girls // //returns the collection of girls from our store, girlReducer
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addScore: (girl) => dispatch(addScore(girl))
    }
}


//export default connect(mapStateToProps,mapDispatchToProps)(Home);
export default compose(
    connect(mapStateToProps,mapDispatchToProps),
    firestoreConnect([
        {collection: 'girls'}
    ]),
)(Home)