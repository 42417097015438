import React, {Component} from 'react';
import {connect} from 'react-redux';
import {addGirl} from '../../store/actions/girlActions';
import firebase from 'firebase';
import FileUploader from "react-firebase-file-uploader";
import {Redirect} from 'react-router-dom';

class AddGirl extends Component{
    state = {
        name: '',
        fileName: '',
        imgFile: null,
        isUploading: false,
        progress: 0,
        imgFileURL: ''
    }

    handleChangeName = event =>
        this.setState({ name: event.target.value });
    
    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    
    handleProgress = progress => this.setState({ progress });
    
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        this.setState({ fileName: filename, progress: 100, isUploading: false });
        firebase
          .storage()
          .ref("images")
          .child(filename)
          .getDownloadURL()
          .then(url => this.setState({ imgFileURL: url }));
      };


    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.imgFileURL != '')
            this.props.addGirl(this.state);
        this.props.history.push('/');
    }

    render(){
        const {auth} = this.props;
        if(!auth.uid) return <Redirect to='/signin'/>
        return(
            <div className="container">
                <form onSubmit={this.handleSubmit}>
                    <h5 className="grey-text text-darken-3">Add Girl</h5>
                    <div className="input-field">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" onChange={this.handleChangeName}/>
                    </div>
                    
                    <div class="file-field input-field">
                        <div className="btn-small black">
                            <span>Add Image</span>
                            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                            {this.state.avatarURL && <img src={this.state.avatarURL} />}
                            <input type="file" id="imgFile" onChange={this.handleChange}/>
                            <FileUploader
                                accept="image/*"
                                randomizeFilename
                                storageRef={firebase.storage().ref("images")}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                            />
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path validate" type="text"/>
                        </div>
                        
                    </div>

                    <div className="input-field center">
                        <button className="btn-large black waves-effect waves-light" type="submit" name="action">Add Girl</button>
                    </div>
                   
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return{
        addGirl: (girl) => dispatch(addGirl(girl))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGirl);
