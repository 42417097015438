import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import firebase from 'firebase';
import {compose} from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class GirlLeaderBoard extends Component{
    state={
        girls: [
            {id: '1', score:'2', name: 'Valentina Lobeira', imgFile: '/img/girl2.jpg'},
            {id: '2', score:'1', name: 'Sofia Betanzo', imgFile: '/img/girl1.jpg'},
    
        ]
    }
    render(){
       // const girls = this.state.girls;
        const {girls, auth} = this.props;
        if(!auth.uid) return <Redirect to='/signin'/>
        const girlList = girls.map(girl => {
            return(
                <tbody>
                    <tr className="black-text" key={girl.id}>
                        <td>{girl.score}</td>
                        <td>{girl.name}</td>
                    </tr>
                </tbody>
                )
            })

        return(
            <div className="container">
                <table >
                    <thead className="black-text">
                    <tr>
                        <th>Score</th>
                        <th>Name</th>
                    </tr>
                    </thead>
                    {girlList}
                </table>
            </div>
        )
    }
}

/*
<tbody>
                    <tr className="black-text">
                        <td>1</td>
                        <td>Sofia Betanzo</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Valentina Lobeira</td>
                    </tr>
                    </tbody>

*/

const mapStateToProps = (state) => {
    return{
        girls: state.firestore.ordered.girls || state.girl.girls,
        auth: state.firebase.auth
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {collection: 'girls', orderBy: ['score', 'desc']}
    ]),
)(GirlLeaderBoard)