const initState = {
    girls: [
        {id: '1', score: '2', name: 'Valentina Lobeira', imgFile: '/img/girl2.jpg'},
        {id: '2', score:'1', name: 'Sofia Betanzo', imgFile: '/img/girl1.jpg'},
        {id: '3', score:'1', name: 'Myrthea Paredes', imgFile: '/img/myrthea.png'},
        {id: '4', score:'1', name: 'Vale Loria', imgFile: '/img/valeLoria.png'},
        {id: '5', score:'1', name: 'Eugenia Rdz', imgFile: '/img/eugerdz.png'},
        {id: '6', score:'1', name: 'Mariana Gonzalez', imgFile: '/img/marianagzz.png'},
        {id: '7', score:'1', name: 'Alexia Meyer', imgFile: '/img/alexia.png'}

    ]
}

const girlReducer = (state=initState, action) => {
    switch(action.type){
        case 'ADD_GIRL':
            console.log("added girl", action.girl)
        case 'ADD_SCORE':
            console.log("added score", action.girl)
        //case 'ADD_SCORE_ERROR':
            //console.log('added score error', action.err)
        default:
            return state;
        
    }
}

export default girlReducer;