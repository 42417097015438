import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import Home from './components/Dashboard/Home';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import addGirl from './components/Girls/addGirl';
import GirlLeaderBoard from './components/Girls/GirlLeaderBoard';

class App extends Component{
  render(){
    return(
      <BrowserRouter>
        <div className="App">
          <Navbar/>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path="/signin" component={SignIn}/>
            <Route path="/signup" component={SignUp}/>
            <Route path="/addgirl" component={addGirl}/>
            <Route path="/leaderboard" component={GirlLeaderBoard}/>
          </Switch>
        </div>
      </BrowserRouter>
      
    )
  }
}


export default App;
