//adds girl
export const addGirl = (girl) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        //generates random number for id
        var rn = require('random-number');
        var gen = rn.generator({
        min:  1,
        max:  1000,
        integer: true
        })
        let idGirl = gen()

        // make async call to database
        const firestore = getFirestore();
        //const girlId = getState().firebase.auth.uid;
        firestore.collection('girls').add({
            ...girl,
            score: 1,
            id: idGirl,
            createdAt: new Date()
        }).then(() => {
            dispatch({type: 'ADD_GIRL', girl: girl});
        }).catch((err) => {
            dispatch({type: 'ADD_GIRL_ERROR', err});
        })
        
    }
};

//add score

export const addScore = (girl) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        // make async call to database
        const firestore = getFirestore();
        let data = firestore;
        firestore.collection("girls").where("id", "==", girl.id)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                data = doc.data();
                firestore.collection('girls').doc(doc.id).update({
                    score: data.score + 1
                }).then(() => {
                    dispatch({type: 'ADD_SCORE', girl: girl});
                }).catch((err) => {
                    dispatch({type: 'ADD_SCORE_ERROR', err});
                })
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
       
    }
};
