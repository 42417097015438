import React from 'react';
import Girl1 from './Girl1';
import Home from './Home';

const GirlSummary = ({girl,handleClick}) => {
    
    //returns girl 1
    //<span className="card-title">{girl.name}</span>
    //onClick={this.handleClick}
    
    return(
        
        <div className="girl-1-section card center">
            <div className="card-image center">
                <img className="responsive-img" src={girl.imgFileURL}></img>
                
            </div>
            <div className="card-action">
                <button className="waves-effect waves-light btn-small black" onClick={() => handleClick(girl)}>{girl.name}</button>
            </div> 
        </div>
    )
    
}
// <Home girlToAddScore={girl}/>
export default GirlSummary;








