import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'; //test

// Your web app's Firebase configuration
var config = {
    apiKey: "AIzaSyCrkNb47_qnQ-BCB8_NlX5glByeciptwT0",
    authDomain: "sampetrinas.firebaseapp.com",
    databaseURL: "https://sampetrinas.firebaseio.com",
    projectId: "sampetrinas",
    storageBucket: "sampetrinas.appspot.com",
    messagingSenderId: "880604994327",
    appId: "1:880604994327:web:473226cfd3b52be3d17552",
    measurementId: "G-RDHL6SSLNJ"
  };
  // Initialize Firebase
  firebase.initializeApp(config);

  const storage = firebase.storage()
  //firebase.firestore().settings({timestampsInSnapshots: true})

  export {storage, firebase as default}