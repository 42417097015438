import React, {Component} from 'react';
import GirlSummary from './GirlSummary';


class Girl2 extends Component{
    click = (girl) => {
        this.props.handleClick(girl)
    }

    render(){
        return(
            <div className="girls-section card center">
                <GirlSummary girl={this.props.girls[Math.floor(Math.random() * this.props.girls.length)]} key={this.props.girls[Math.floor(Math.random() * this.props.girls.length)].id} handleClick={this.click}/>
            </div>
        )
    }
    
    
}
/*
const Girl2 = ({girls, handleClick}) => {
    //returns girl 1
    let girl = girls[Math.floor(Math.random() * girls.length)];
    return(
        <div className="girls-section card center">
            <GirlSummary girl={girl} key={girl.id}/>
        </div>
    )
    
}
*/
export default Girl2;














