import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';

const SignInLinks = (props) => {
    return(
        <ul className="right">
            <li><NavLink to="/addgirl" className="black-text">Add Girl</NavLink></li>
            <li><NavLink to="/leaderboard" className="black-text">LeaderBoard</NavLink></li>
            <li><a className="black-text" onClick={props.signOut}>Log out</a></li>
            <li><NavLink to="/" className='btn btn-floating black white-text'>{props.profile.initials}</NavLink></li>
        </ul>
    )
}

const mapDispatchToProps = (dispatch) => {
    return{
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignInLinks);