import girlReducer from './girlReducer';
import authReducer from './authReducer';
import {combineReducers} from 'redux';
import {firestoreReducer} from 'redux-firestore';
import {firebaseReducer} from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    girl: girlReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;